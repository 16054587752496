import { useLazyQuery } from '@apollo/client';
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { CHART_X_AXIS_WITH_EPC, DATE_FORMATS } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { WITH_EPC_DROP_OFF_RATES } from '../graphql/Queries';
import CommonEpcChart from './CommonEpcChart';
import CompanySelect from './CompanySelect';

const { RangePicker } = DatePicker;

const WithEpcChart = () => {
  const [withEpcDropOff, setWithEpcDropOff] = useState([]);
  const [isWithEpcDropOffLoading, setIsWithEpcDropOffLoading] = useState({});
  const [selectedCompanyWithEpc, setSelectedCompanyWithEpc] = useState('');
  const [selectedDateRangeWithEpc, setSelectedDateRangeWithEpc] = useState({});

  const [withEpcDropOffRates] = useLazyQuery(WITH_EPC_DROP_OFF_RATES, {
    onCompleted: (response) => {
      setWithEpcDropOff(response?.epcDropOffRates);
      setIsWithEpcDropOffLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    setIsWithEpcDropOffLoading(true);
    withEpcDropOffRates();
  }, []);

  useEffect(() => {
    setIsWithEpcDropOffLoading(true);
    const filters = {
      dateRange:
        selectedDateRangeWithEpc?.from && selectedDateRangeWithEpc?.to
          ? {
              from: selectedDateRangeWithEpc?.from,
              to: selectedDateRangeWithEpc?.to,
            }
          : undefined,
      organizationId: selectedCompanyWithEpc || undefined,
    };
    withEpcDropOffRates({
      variables: {
        filters,
      },
    });
  }, [selectedCompanyWithEpc, selectedDateRangeWithEpc]);

  const onRangePickerChangeWithEpc = (values) => {
    const rangeObj = {
      from: values?.[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };
    setSelectedDateRangeWithEpc(values ? rangeObj : {});
  };

  const withEpcDropOffWithLabel = withEpcDropOff
    ?.map((data) => ({
      count: data?.count,
      type: CHART_X_AXIS_WITH_EPC?.[data?.type]?.label,
      order: CHART_X_AXIS_WITH_EPC?.[data?.type]?.order,
    }))
    ?.sort((a, b) => a?.order - b?.order);

  return (
    <>
      <div className="d-flex align-center justify-between mb-40 chart-filter">
        <h3>With EPC Drop Off Rates</h3>
        <div className="ml-16">
          <CompanySelect
            className="filter-select mr-16"
            onChange={(value) => setSelectedCompanyWithEpc(value)}
          />
          <RangePicker
            popupClassName="picker-with-scroll"
            format={DATE_FORMATS?.REGULAR}
            onChange={onRangePickerChangeWithEpc}
            disabledDate={(current) =>
              current && current?.startOf('day') > moment()?.startOf('day')
            }
            placeholder={['From Date', 'To Date']}
          />
        </div>
      </div>
      {isWithEpcDropOffLoading ? (
        <LoaderComponent setHeight={50} />
      ) : (
        <CommonEpcChart data={withEpcDropOffWithLabel} />
      )}
    </>
  );
};

export default WithEpcChart;
